<template>
  <div class="userAuthorization content">
    <div class="contentbox">
      <el-container>
        <el-scrollbar style="min-width: 300px !important; height: 100%">
          <el-aside style="margin-right: 0 !important">
            <div class="tree-container">
              <el-tree
                class="tree filter-tree"
                :data="dataRiver"
                :props="defaultProps"
                :filter-node-method="filterNode"
                :expand-on-click-node="false"
                :default-expanded-keys="defaultExpaneded"
                :highlight-current="true"
                :row-key="id"
                :node-key="id"
                :current-node-key="currentNodekey"
                @node-click="handleNodeClick"
                ref="treeRiver"
              >
                <span slot-scope="{ node, data }">
                  <i v-if="data.children" class="el-icon-folder"></i>
                  <!-- <i v-else class="el-icon-collection-tag"></i> -->
                  <i v-else class="el-icon-tickets"></i>
                  <span>{{ node.label }}</span>
                </span>
              </el-tree>
              <div class="p-t-10"></div>
            </div>
          </el-aside>
        </el-scrollbar>
        <el-scrollbar class="wfull">
          <el-main>
            <div ref="rightDevW" class="rightBox">
              <TableQuery
                :formList="formList"
                :formInline="formInline"
                :customArrKey="customArrKey"
                :btnList="btnList"
                :property="queryProperty"
                @btnClickFun="btnClickFun"
                @moveQueryFun="queryBackFun"
                @formDataFun="formDataFun"
              >
              </TableQuery>
              <div class="p-t-10"></div>
              <div ref="tableBox">
                <SuperTable
                  ref="mytable"
                  :tableData="tableData"
                  :loading="tableLoading"
                  :property="tableProperty"
                  :pageData="pageData"
                  @handleCurrentChange="handleCurrentChange"
                  @handleSizeChange="handleSizeChange"
                  @operationClick="operationClick"
                ></SuperTable>
              </div>
            </div>
          </el-main>
        </el-scrollbar>
      </el-container>
    </div>
  </div>
</template>
<script>
import configBasics from "@/utils/configBasics";
import TableQuery from "@/common/module/TableQuery";
import SuperTable from "@/common/module/SuperTable";
export default {
  name: "userAuthorization",
  components: {
    TableQuery,
    SuperTable
  },
  data() {
    return {
      ops: configBasics.vueScrollOps,
      formList: [
        {
          name: "userName",
          label: "人员姓名",
          showhide: true,
          type: "text",
          clearable: true,
          placeholder: "请输入视频名称"
        }
      ],
      formInline: {
        userName: ""
      },
      customArrKey: [],
      btnList: [
        {
          name: "searchButton",
          icon: "icon-tianjia",
          label: "查询",
          type: "searchButton"
        }
      ],
      queryProperty: {
        labelWidth: "120px",
        typeName: "cedian",
        moveLabelWidth: "120px"
      },
      /* 表格数据 */
      tableData: {
        listData: [],
        titleData: [],
        btnData: []
      },
      /* 表格加载 */
      tableLoading: false,
      /* 表格属性 */
      tableProperty: {
        typeName: "",
        emptyText: "",
        fixheight: false,
        isOrder: true, // 设置表格有序号
        // border: true, // 设置表格有边框
        operationWidth: "140px"
      },
      /* 分页 */
      pageData: {
        currentPage: 1,
        pageSizes: [10, 20, 30, 40, 50],
        pageSize: 10,
        layout: "total, sizes, prev, pager, next, jumper",
        total: 0
      },
      loading: false,
      btnAuthority: false,
      dataRiver: [],
      defaultExpaneded: [], //默认展开的节点
      defaultProps: {
        children: "children",
        label: "label"
      },
      id: "id",
      orgID: "",
      currentNodekey: "" //默认选中的节点树
    };
  },
  async created() {
    await configBasics.departmentTree().then(res => {
      this.dataRiver = [{ id: "", label: "全部", children: res }];
      this.defaultExpaneded = [this.dataRiver[0].children[0].id];
      this.defaultExpaneded = [""];
      this.currentNodekey = "";
      this.$nextTick(() => {
        this.$refs.treeRiver.setCurrentKey(this.currentNodekey); //一定要加这个选中了否则样式没有出来
      });
    });
    this.resetArr();
    this.getTableList(); // 表格列表数据
  },
  mounted() {
    this.cssFun();
    let that = this;
    // eslint-disable-next-line prettier/prettier
    window.onresize = function () {
      that.cssFun();
    };
  },
  methods: {
    /*样式*/
    cssFun() {
      let that = this;
      // eslint-disable-next-line prettier/prettier
      that.$nextTick(function () {
        // eslint-disable-next-line prettier/prettier
        setTimeout(function () {
          that.$refs.tableBox.style.height =
            window.innerHeight -
            that.$refs.tableBox.offsetTop -
            120 -
            48 +
            "px";
          if (that.$refs.mytable) {
            that.$refs.mytable.tableCssFun();
            that.$refs.mytable.scrollTableCssFun();
          }
        }, 200);
      });
    },
    resetArr() {
      this.tableData.titleData = [
        {
          headeralign: "center",
          prop: "userName",
          showoverflowtooltip: true,
          showhide: true,
          label: "人员姓名",
          align: "center"
        },
        {
          headeralign: "center",
          prop: "phone",
          showoverflowtooltip: true,
          showhide: true,
          label: "联系电话",
          align: "center"
        },
        {
          headeralign: "center",
          prop: "count",
          showoverflowtooltip: true,
          showhide: true,
          label: "被授权视频数",
          align: "center"
        }
      ];
      this.btnAuthority =
        this.$store.state.operationPower.edit.SHJ_ControllerUnit;
      if (this.btnAuthority) {
        this.btnList = [
          {
            name: "searchButton",
            icon: "icon-tianjia",
            label: "查询",
            type: "searchButton"
          }
        ];
        this.tableData.btnData = [
          {
            name: "detailsButton",
            icon: "icon-video-quanxianguanli",
            label: "查看权限",
            type: "detailsButton"
          }
        ];
      } else {
        this.btnList = [
          {
            name: "searchButton",
            icon: "icon-tianjia",
            label: "查询",
            type: "searchButton"
          }
          // {
          //   name: "addButton",
          //   icon: "icon-xinzeng",
          //   label: "新增",
          //   type: "addButton"
          // }
        ];
        this.tableData.btnData = [
          {
            name: "detailsButton",
            icon: "icon-video-quanxianguanli",
            label: "查看权限",
            type: "detailsButton"
          },
          {
            name: "editButton",
            icon: "icon-video-shouquan",
            label: "授权",
            type: "editButton"
          }
        ];
      }
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    handleNodeClick(data) {
      // console.log(data);
      this.currentNodekey = data.id;
      this.orgID = data.id;
      this.pageData.currentPage = 1;
      this.getTableList();
    },
    getTableList() {
      let obj = {
        userName: this.formInline.userName,
        orgID: this.orgID,
        page: this.pageData.currentPage,
        rows: this.pageData.pageSize
      };
      this.$api("videoManage.getUserVideoAuthList")
        .invoke(obj)
        .then(({ data: { code, data } }) => {
          if (code === 1) {
            if (data) {
              this.tableData.listData = data.data;
              this.pageData.total = data.total;
            }
          } else {
            this.$message({
              showClose: true,
              message: data,
              type: "error",
              duration: 1000
            });
          }
        })
        .catch(() => {});
    },
    /* 当前页-改变事件 */
    handleCurrentChange(val) {
      this.pageData.currentPage = val;
      this.getTableList();
    },
    /* 每页多少条-改变事件 */
    handleSizeChange(val) {
      this.pageData.currentPage = 1;
      this.pageData.pageSize = val;
      this.getTableList();
    },
    // 表格列表上的点击事件
    operationClick(item, scope) {
      let that = this;
      if (item.name === "detailsButton") {
        this.$router.push({
          path: "/index/leftMenuIndex/userAuthorizationDetail",
          query: {
            menuid: this.$route.query.menuid,
            sysmodel: this.$route.query.sysmodel,
            moduleName: this.$route.query.moduleName,
            userName: scope.row.userName,
            userId: scope.row.userId
          }
        });
      } else if (item.name === "editButton") {
        console.log(scope.row);
        this.$router.push({
          path: "/index/leftMenuIndex/userAuthorizationEdit",
          query: {
            menuid: this.$route.query.menuid,
            sysmodel: this.$route.query.sysmodel,
            moduleName: this.$route.query.moduleName,
            userId: scope.row.userId
          }
        });
      }
    },
    // 删除
    deleteFun(val) {
      let obj = {
        pkId: val.pkId
      };
      this.$api("videoManage.deleteSdlVideo")
        .invoke(obj)
        .then(({ data: { code, data } }) => {
          if (code === 1) {
            this.$message({
              showClose: true,
              message: "删除成功！",
              type: "success",
              duration: 1000
            });
            this.getTableList();
          } else {
            this.$message({
              showClose: true,
              message: data,
              type: "error",
              duration: 1000
            });
          }
        });
    },
    // 按钮点击事件
    btnClickFun(item) {
      if (item.name === "searchButton") {
        // 查询
        this.pageData.currentPage = 1;
        this.getTableList();
      } else if (item.name === "addButton") {
        this.$router.push({
          path: "/index/leftMenuIndex/videoManageEdit",
          query: {
            menuid: this.$route.query.menuid,
            sysmodel: this.$route.query.sysmodel,
            moduleName: this.$route.query.moduleName
          }
        });
      }
    },
    // 回车查询
    queryBackFun(formInline) {
      this.formInline = formInline;
      // 查询
      this.pageData.currentPage = 1;
      this.getTableList();
    },
    // 查询标签点击事件
    formDataFun(formInline) {
      this.formInline = formInline;
    }
  }
};
</script>
<style scoped lang="less">
.icon-video-right-1 {
  font-size: 20px;
  color: #41bf3a;
}
/deep/.el-container .wfull {
  width: 100%;
}
</style>
